import { render, staticRenderFns } from "./GoldOfPersiaModal.vue?vue&type=template&id=7ad74ac7&scoped=true&"
import script from "./GoldOfPersiaModal.vue?vue&type=script&lang=js&"
export * from "./GoldOfPersiaModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad74ac7",
  null
  
)

export default component.exports